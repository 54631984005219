import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';

import Image from 'components/common/Image';

// eslint-disable-next-line react/prefer-stateless-function
class ImageLazyLoader extends Component {
  render() {
    const {
      props: { imgProps, pictureProps, imageLoadedCallback, className, imageClassName }
    } = this;

    const props = {
      ...imgProps,
      className: cn(className, imageClassName),
      onLoad: imageLoadedCallback,
      pictureProps: pictureProps
    };
    return <Image {...props} />;
  }
}

ImageLazyLoader.defaultProps = {
  pictureProps: []
};

ImageLazyLoader.propTypes = {
  /** these are the props passed to the nested image. you can put anything you want here. */
  imgProps: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
  }).isRequired,

  /** optional className to pass down to containing span */
  className: PropTypes.string,

  /** make an optional picture element with srcSet. needed for landing pages */
  pictureProps: PropTypes.array,

  imageLoadedCallback: PropTypes.func, // function pointer to tell the parent component when the image has loaded.

  /** optional className to pass down to Image component */
  imageClassName: PropTypes.string
};

export default ImageLazyLoader;

import { useRef, useState } from 'react';
import { connect } from 'react-redux';

import { PROFILE_PAGE_REMINDER, REMINDER_MESSAGE_PROFILE } from 'constants/influencerMessages';
import MelodyModal from 'components/common/MelodyModal';
import useMartyContext from 'hooks/useMartyContext';
import { createHeartList, createInfluencerCollection } from 'actions/hearts';

import css from 'styles/components/account/collectionModals/newCollectionModal.scss';

export const NewCollectionModal = ({
  onDoneModal,
  showNewCollectionModal,
  onCancelModal,
  createHeartList,
  createInfluencerCollection,
  productImages = [],
  content = {
    heading: 'Create New List',
    label: 'Name your List',
    markInfluencer: false
  }
}) => {
  // Save collection lifecycle controls
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  // Collection name ref
  const input = useRef();

  // Collection/List Heading and Label
  const { heading, label, markInfluencer } = content;

  // Testing params
  const { testId } = useMartyContext();

  // Modal callback
  const onAfterOpen = () => {
    setHasError(false);
    input.current?.focus();
  };

  // Form submit handler
  const onSaveNewCollection = e => {
    e.preventDefault();
    const {
      collectionName: { value }
    } = e.target;

    // Resets the lifecycle controls
    setIsLoading(true);
    setHasError(false);
    const createCollection = markInfluencer ? createInfluencerCollection : createHeartList;
    createCollection({ listName: value })
      .then(onDoneModal) // Succeeded in creating Collection
      .catch(() => setHasError(true)) // Failed
      .finally(() => setIsLoading(false)); // Lifecycle update
  };

  // Attempts to retrieve the featured image (first image from the product bundle list)
  const featuredImage = productImages[0] || false;

  return (
    <MelodyModal
      className={css.modal}
      heading={heading}
      headingTestId="newCollectionModalHeader"
      isOpen={showNewCollectionModal}
      onAfterOpen={onAfterOpen}
      onRequestClose={onCancelModal}
      overlayClassName={css.overlay}
    >
      <div>
        <form method="post" onSubmit={onSaveNewCollection}>
          <div className={css.formContentWrapper}>
            {featuredImage && (
              <div className={css.productImage}>
                <img alt={featuredImage.alt} src={featuredImage.featured.src} srcSet={featuredImage.featured.retinaSrc} />
              </div>
            )}
            <div className={css.fields}>
              {markInfluencer && <p className={css.reminderMsg}>{`${REMINDER_MESSAGE_PROFILE} ${PROFILE_PAGE_REMINDER}`}</p>}
              <label htmlFor="collectionName" data-test-id={testId('nameYourListLabel')}>
                {label}
              </label>
              <input required ref={input} name="collectionName" id="collectionName" type="text" data-test-id={testId('newCollectionNameInputBox')} />
              {hasError && (
                <div className={css.errorMsg} data-test-id={testId('newCollectionError')}>
                  <p>Oh No! There was an issue creating your collection. Please try again.</p>
                </div>
              )}
            </div>
          </div>

          <div className={css.footer}>
            <button type="button" onClick={onCancelModal} data-test-id={testId('cancelButton')}>
              Cancel
            </button>
            <button type="submit" disabled={isLoading} data-test-id={testId('createButton')}>
              Create & save
            </button>
          </div>
        </form>
      </div>
    </MelodyModal>
  );
};

const ConnectedNewCollectionModal = connect(null, {
  createHeartList,
  createInfluencerCollection
})(NewCollectionModal);

export default ConnectedNewCollectionModal;
